import React from 'react';
import i18next from 'i18n';
import styles from './basic.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import _ from 'lodash';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

type BasicProps = {
  agencyDetail: {};
  isSystemViewOnly: boolean;
};

function onOrOff (on) {
  if (on) {
    return i18next.t<string>('agency.yes');
  } else {
    return i18next.t<string>('agency.no');
  }
}

function percent (str) {
  return str + '%';
}

const dataFormatter = {
  selfBuy: onOrOff,
  language: value => i18next.t<string>(`common.language.${value.replace('_', '').toLowerCase()}`),
  noLimitSelfBuyStatus: onOrOff,
  interstitial: onOrOff,
  agcPercent: percent,
  'addonProps.maxOrderProfit': percent,
  sysPercentIn: percent,
  sysPercentOut: percent
};

const Basic: React.FC<BasicProps> = ({
  agencyDetail,
  isSystemViewOnly
}) => {

  function getFieldData (fields) {
    return fields
      .filter(field => {
        const value: any = _.get(agencyDetail, field, undefined);
        return value !== undefined && value !== null && value !== '';
      })
      .map(field => ({
        label: i18next.t<string>(`agency.${field}`),
        value: dataFormatter[field] ? dataFormatter[field](_.get(agencyDetail, field)) : _.get(agencyDetail, field)
      }));
  }

  agencyDetail['limitAdxStr'] = _.isEmpty(agencyDetail['limitAdx']) ?
    undefined :
    `${i18next.t<string>('limitation.' + agencyDetail['limitAdxOp'])}\n${agencyDetail['limitAdx'].map(function (el) { return el.label; }).join('\n')}`;
  agencyDetail['defaultCountryStr'] = _.isEmpty(agencyDetail['defaultCountry']) ?
    undefined :
    `${i18next.t<string>('limitation.' + agencyDetail['defaultCountryOp'])}\n${agencyDetail['defaultCountry'].map(function (el) { return el.label; }).join('\n')}`;
  agencyDetail['defaultSpaceStr'] = _.isEmpty(agencyDetail['defaultSpace']) ?
    undefined :
    `${i18next.t<string>('limitation.' + agencyDetail['defaultSpaceOp'])}\n${agencyDetail['defaultSpace'].map(function (el) { return el.label; }).join('\n')}`;

  agencyDetail['limitPublisherCountryStr'] = agencyDetail['limitPublisherCountry'].map(function (el) {
    const i18nKey = `geography.country.${el.value.toLowerCase()}`;
    return i18next.exists(i18nKey) ? i18next.t<string>(`geography.country.${el.value.toLowerCase()}`) : el.label;
  }).join('\n');
  agencyDetail['limitPublisherStr'] = agencyDetail['limitPublisher'].map(function (el) { return el; }).join('\n');
  agencyDetail['segmentCountryStr'] = agencyDetail['segmentCountry'].map(function (el) {
    const i18nKey = `geography.country.${el.value.toLowerCase()}`;
    return i18next.exists(i18nKey) ? i18next.t<string>(`geography.country.${el.value.toLowerCase()}`) : el.label;
  }).join('\n');
  agencyDetail['segmentLabelStr'] = agencyDetail['segmentLabel'].map(function (el) { return el.label; }).join('\n');

  const agencyInfoFields = ['id', 'companyName', 'language', 'currency', 'timeZone', 'selfBuy', 'limitPublisherStr', 'noLimitSelfBuyStatus'];
  isSystemViewOnly && agencyInfoFields.push('comComment');
  const agencyInfoData = getFieldData(agencyInfoFields);
  const logoUrl = _.get(agencyDetail, 'logoData.url');
  logoUrl && agencyInfoData.push({
    label: i18next.t<string>('agency.logo'),
    value: (
      <ImagePreview
        url={logoUrl}
        config={{
          showName: false,
          showSize: false
        }}
      />
    )
  });
  const commonDeliverySettingFields = ['targetBudgetMinimum', 'campaignBudgetMinimum', 'limitPublisherCountryStr', 'limitAdxStr', 'defaultCountryStr', 'defaultSpaceStr'];
  const rtbDeliverySettingFields = ['limitAdxStr'];
  isSystemViewOnly && rtbDeliverySettingFields.push('segmentCountryStr', 'segmentLabelStr', 'interstitial');
  const commonDeliverySettingData = getFieldData(commonDeliverySettingFields);
  const rtbDeliverySettingData = getFieldData(rtbDeliverySettingFields);
  const marginSettingFields = ['sysPercentIn', 'agcPercent', 'addonProps.maxOrderProfit'];
  const marginSettingData = getFieldData(marginSettingFields);

  return (
    <div className={styles.container}>
      <InfoDisplaySection data={agencyInfoData} title={i18next.t<string>('agency.information')} />
      <InfoDisplaySection data={commonDeliverySettingData} title={i18next.t<string>('agency.form.titles.generalDeliverySetting')} />
      <InfoDisplaySection data={rtbDeliverySettingData} title={i18next.t<string>('agency.form.titles.rtbDeliverySetting')} />
      <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin, RoleNames.goJekAccountManager)}>
        <InfoDisplaySection data={marginSettingData} title={i18next.t<string>('agency.marginSetting')} />
      </PermissionChecker>
    </div>
  );
};

export default Basic;
