import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { DefaultAdRequestSourceManager, AdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { AddonFeatureManager } from 'core';
import { CreativeType, isNative } from 'core/creative/Creative';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import _ from 'lodash';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ROUTE_PATH } from 'enum/RoutePath';
import { CreativeFormBasicData, CreativeFormData, FormContentModel } from './FlowSteps/SubSteps/FormContent/FormContentModel';
import {
  ComboSummaryModel,
  CreateImageMultipleSummaryModel,
  CreativeSummaryModel,
  HTML5SummaryModel,
  ImageSummaryModel,
  NativeSummaryModel,
  OneForAllDisplaySummaryModel,
  OneForAllVideoSummaryModel,
  PilotTVSummaryModel,
  PPSSummaryModel,
  RetailNativeProductSummaryModel,
  RetailRichMediaProductSummaryModel,
  VideoSummaryModel,
  EdiMaxSummaryModel,
  CustomLayoutSummaryModel,
  PICSummaryModel,
  HamiVideoNativeSummaryModel,
  HamiVideoImageSummaryModel,
  HamiVideoVideoSummaryModel,
  HamiVideoComboSummaryModel,
  CreateHamiVideoImageMuitipleSummaryModel,
  HamiVideoThirdPartySummaryModel,
  SandboxOutdoorSummaryModel
} from './FlowSteps/SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from './CreativeSetupFlowDataContext';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import {
  CreateCreativeAndBindAdSetSummaryStepModel,
  CreateCreativeAndBindRTBCampaignSummaryStepModel,
  CreateCreativeSummaryStepModel,
  CreativeSummaryStepModel,
  EditCreativeSummaryStepModel
} from './FlowSteps/CreativeSummaryStepModel';
import { CreateImageFormModel, ImageFormModel } from './FlowSteps/SubSteps/FormContent/ImageFormModel';
import { CreativeSetupStepModel, DefaultCreativeSetupStepModel } from './FlowSteps/CreativeSetupStepModel';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { computeChecksumMd5 } from 'utils/Md5Utils';
import { OneForAllDisplayFormModel } from './FlowSteps/SubSteps/FormContent/OneForAllDisplayFormModel';
import { OneForAllVideoFormModel } from './FlowSteps/SubSteps/FormContent/OneForAllVideoFormModel';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType, RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { RetailRichMediaProductFormModel } from './FlowSteps/SubSteps/FormContent/RetailRichMediaProductFormModel';
import { RetailNativeProductFormModel } from './FlowSteps/SubSteps/FormContent/RetailNativeProductFormModel';
import { PilotTVFormModel } from './FlowSteps/SubSteps/FormContent/PilotTVFormModel';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { NativeFormModel } from './FlowSteps/SubSteps/FormContent/NativeFormModel';
import { VideoFormModel } from './FlowSteps/SubSteps/FormContent/VideoFormModel';
import { PPSFormModel } from './FlowSteps/SubSteps/FormContent/PPSFormModel';
import { Html5FormModel } from './FlowSteps/SubSteps/FormContent/Html5FormModel';
import { ComboFormModel } from './FlowSteps/SubSteps/FormContent/ComboFormModel';
import { EdiMaxFormModel } from './FlowSteps/SubSteps/FormContent/EdiMaxFormModel';
import { CtvFormModel } from './FlowSteps/SubSteps/FormContent/CtvFormModel';
import { AdLogo } from 'core/adLogo/AdLogo';
import { CustomLayoutFormModel } from './FlowSteps/SubSteps/FormContent/CustomLayoutFormModel';
import { PICFormModel } from './FlowSteps/SubSteps/FormContent/PICFormModel';
import { Order } from 'core/order/Order';
import { Advertiser } from 'core/advertiser/Advertiser';
import { HamiVideoNativeFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoNativeFormModel';
import { CreateHamiVideoImageFormModel, HamiVideoImageFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoImageFormModel';
import { HamiVideoComboFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoComboFormModel';
import { CreateHamiVideoConnectedTVImageFormModel, HamiVideoConnectedTVImageFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoConnectedTvImageFormModel';
import { HamiVideoVideoFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoVideoFormModel';
import { HamiVideoThirdPartyFormModel } from './FlowSteps/SubSteps/FormContent/HamiVideoThirdPartyFormModel';
import { SandboxOutdoorFormModel } from './FlowSteps/SubSteps/FormContent/SandboxOutdoorFormModel';
import { PoyaFormModel } from './FlowSteps/SubSteps/FormContent/PoyaFormModel';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';

type RedirectData = {
  pathname: string;
  search?: string,
  state?: any;
};
export interface CreativeSetupFlowPageModel {
  readonly type: string;
  readonly state: CreativeSetupFlowState;
  readonly event: UpdateEventListener<CreativeSetupFlowPageModel>;
  readonly tenmaxCategories: Array<SelectOptions>;
  readonly advertisers: Array<SelectOptions>;
  readonly l1ObjectId?: string;
  readonly campaignId?: string;
  readonly orderNumber?: string;
  readonly draftId?: string;
  readonly supportedCreativeType: CreativeType[];
  readonly dataContenxt: CreativeSetupFlowDataContextType;
  readonly canChooseAdvertiser: boolean;
  readonly needSetupLimitation: boolean;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly defaultAdLogo: AdLogo;
  setEnableAdLogo: (enable: boolean) => void;
  setCreative (creative: CreativeFormData): void;
  init (): void;
  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel;
  onUnmount (handler): void;
  onChooseAdvertiser (advertiserId: number): Promise<void>;
  setRedirectData (redirectData?: RedirectData): void;
  setFinishedRedirectData (redirectData?: RedirectData): void;
  cancel (): void;
  getCreativeSetupStepModel (
    currentCreativeType: CreativeType,
    type: string,
    activeTab: number,
    goSubStep: (subStepIndex: number) => void,
    goLast: (() => void) | undefined,
    goNext: () => void,
    addonFeatureManager: AddonFeatureManager,
    registerValidateMethod: (validateMethod) => void
  ): CreativeSetupStepModel;
  addCache (key: string, data: any): void;
  getCache (key: string): any;
}

export type CreativeSetupFlowProps = {
  readonly model: CreativeSetupFlowPageModel;
};

export type CreativeSetupFlowState = {
  readonly loading: boolean;
  readonly redirectData?: RedirectData;
  readonly creative?: CreativeFormData;
  readonly finished: boolean;
  readonly enableAdLogo: boolean;
};

export abstract class DefaultCreativeSetupFlowPageModel implements CreativeSetupFlowPageModel {

  event: FireableUpdateEventListener<CreativeSetupFlowPageModel>;
  loading: boolean;
  redirectData?: RedirectData;
  initCreative: any;
  creative?: CreativeFormData;
  tenmaxCategories: Array<SelectOptions>;
  finished: boolean;
  creativeSetupStepModel?: CreativeSetupStepModel;
  nativeToBannertemplates: { [size: string]: { templatePath?: string, htmlContent?: string } } = {
    '300x250': {},
    '320x100': {},
    '300x600': {}
  };
  uploadedFiles: { [key: string]: string } = {};
  cache: { [key: string]: string } = {};
  enableAdLogo: boolean = false;
  advertiser?: Advertiser;
  validCreativeTypes = this.creativeManager.getCreativeTypes();
  contentFormModelCache: { [key: string]: FormContentModel } = {};

  constructor (
    public canChooseAdvertiser: boolean,
    public advertisers: Array<SelectOptions>,
    public addonFeatureManager: AddonFeatureManager,
    public defaultAdLogo: AdLogo,
    public campaignId?: string,
    public orderNumber?: string,
    public l1ObjectId?: string,
    public draftId?: string,
    protected adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupFlowPageModel>();
    this.loading = false;
    this.tenmaxCategories = [];
    this.finished = false;
    this.getFormContentModelOfType = this.getFormContentModelOfType.bind(this);
  }

  abstract get type ();

  abstract init ();

  get forPmp () {
    return false;
  }

  getInitCreativeType () {
    return CreativeType.ONE_FOR_ALL_DISPLAY;
  }

  getUploadedFileData = async (file: File) => {
    const fileCheckSum = await computeChecksumMd5(file);
    return this.uploadedFiles[fileCheckSum];
  }

  addUploadedFilesData = async (file, url) => {
    const fileCheckSum = await computeChecksumMd5(file);
    this.uploadedFiles[fileCheckSum] = url;
  }

  initNativeBannerTemplates = async () => {
    const templateUrls = await this.creativeManager.getCreativeNativeToBannerTemplate();
    await Promise.all(
      templateUrls.map(async url => {
        try {
          const regex = /Bannersize-Native-(.*)-template/;
          const matches = url.match(regex);
          if (matches && matches[1]) {
            const size = matches[1].replace('-', 'x');
            const response = await fetch(url);
            this.nativeToBannertemplates[size] = {
              templatePath: url,
              htmlContent: await response.text()
            };
          }
        } catch (e) {}
      })
    );
  }

  get dataContenxt (): CreativeSetupFlowDataContextType {
    return {
      creative: this.creative,
      initCreative: this.initCreative,
      advertisers: this.advertisers,
      enableAdLogo: this.enableAdLogo,
      forPmp: this.forPmp,
      tenmaxCategories: this.tenmaxCategories,
      supportedCreativeType: this.supportedCreativeType,
      advertiser: this.advertiser,
      setCreative: this.setCreative,
      setFinishedRedirectData: this.setFinishedRedirectData,
      getFormContentModelOfType: this.getFormContentModelOfType.bind(this),
      getSummaryModel: this.getSummaryModel.bind(this)
    };
  }

  get needSetupLimitation () {
    return true;
  }

  get supportedCreativeType () {
    return this.validCreativeTypes.reduce((acc, type) => {
      if (this.creativeManager.isCreativeTypeSupport(type, this.addonFeatureManager)) {
        acc.push(type);
      }
      return acc;
    }, [] as CreativeType[]);
  }

  setEnableAdLogo = (enableAdLogo: boolean) => {
    this.enableAdLogo = enableAdLogo;
    this.updateState(false);
  }

  getCreativeSetupStepModel (
    creativeType: CreativeType,
    type: string,
    activeTab: number,
    goSubStep: (subStepIndex: number) => void,
    goLast: (() => void) | undefined,
    goNext: () => void,
    addonFeatureManager: AddonFeatureManager,
    registerValidateMethod: (validateMethod) => any
  ): CreativeSetupStepModel {
    if (this.creativeSetupStepModel) {
      this.creativeSetupStepModel.updateProps(activeTab);
      return this.creativeSetupStepModel;
    }
    this.creativeSetupStepModel = new DefaultCreativeSetupStepModel(
      creativeType,
      type,
      activeTab,
      goSubStep,
      goLast,
      goNext,
      addonFeatureManager,
      registerValidateMethod,
      this.setEnableAdLogo
    );
    return this.creativeSetupStepModel;
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel;

  setCreative = (creative: CreativeFormData) => {
    if (this.event.count === 0) { // already unmount
      return;
    }
    this.creative = creative;
    this.updateState(false);
  }

  get state (): CreativeSetupFlowState {
    return {
      loading: this.loading,
      redirectData: this.redirectData,
      creative: this.creative,
      finished: this.finished,
      enableAdLogo: this.enableAdLogo
    };
  }

  setRedirectData (redirectData?: RedirectData) {
    this.redirectData = redirectData;
  }

  setFinishedRedirectData = (redirectData?: RedirectData) => {
    this.redirectData = redirectData;
    this.finished = true;
    this.updateState(false);
  }

  async initFormContentModel (formContentModel: FormContentModel) {
    this.updateState(true);
    await formContentModel.init();
    this.updateState(false);
  }

  async getFormContentModelOfType (type: CreativeType, advertiserId: number, needInit: boolean = true): Promise<FormContentModel> {
    if (this.contentFormModelCache[type]) {
      return this.contentFormModelCache[type];
    }
    let contentFormModel;
    switch (type) {
      case CreativeType.ONE_FOR_ALL_DISPLAY:
        contentFormModel = new OneForAllDisplayFormModel(this, this.nativeToBannertemplates);
        break;
      case CreativeType.IMAGE:
        contentFormModel = new ImageFormModel(this);
        break;
      case CreativeType.NATIVE:
        contentFormModel = new NativeFormModel(this, this.nativeToBannertemplates);
        break;
      case CreativeType.VIDEO:
        contentFormModel = new VideoFormModel();
        break;
      case CreativeType.THIRD_PARTY:
        contentFormModel = new PPSFormModel();
        break;
      case CreativeType.HTML5:
        contentFormModel = new Html5FormModel(this);
        break;
      case CreativeType.COMBO:
        contentFormModel = new ComboFormModel();
        break;
      case CreativeType.ONE_FOR_ALL_VIDEO:
        contentFormModel = new OneForAllVideoFormModel(this);
        break;
      case CreativeType.EDIMAX:
        contentFormModel = new EdiMaxFormModel(advertiserId);
        break;
      case CreativeType.PIC_SHORT:
        contentFormModel = new PICFormModel(advertiserId, 0, 15, this.forPmp);
        break;
      case CreativeType.PIC_LONG:
        contentFormModel = new PICFormModel(advertiserId, 16, 30, this.forPmp);
        break;
      case CreativeType.PIC_EXTENDED:
        contentFormModel = new PICFormModel(advertiserId, 31, 60, this.forPmp);
        break;
      case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
        contentFormModel = new RetailRichMediaProductFormModel(this);
        break;
      case CreativeType.RETAIL_NATIVE_PRODUCT:
        contentFormModel = new RetailNativeProductFormModel(this);
        break;
      case CreativeType.PILOT_TV:
        contentFormModel = new PilotTVFormModel();
        break;
      case CreativeType.CTV:
        contentFormModel = new CtvFormModel();
        break;
      case CreativeType.CUSTOM_BOTTOM:
      case CreativeType.CUSTOM_RECTANGLE:
        contentFormModel = new CustomLayoutFormModel(type);
        break;
      case CreativeType.HAMI_VIDEO_NATIVE:
        const nativeToBannertemplates = {
          '300x250': this.nativeToBannertemplates['300x250'],
          '320x100': this.nativeToBannertemplates['320x100']
        };
        contentFormModel = new HamiVideoNativeFormModel(this, nativeToBannertemplates);
        break;
      case CreativeType.HAMI_VIDEO_IMAGE:
        contentFormModel = new HamiVideoImageFormModel(this);
        break;
      case CreativeType.HAMI_VIDEO_VIDEO:
        contentFormModel = new HamiVideoVideoFormModel();
        break;
      case CreativeType.HAMI_VIDEO_COMBO:
        contentFormModel = new HamiVideoComboFormModel();
        break;
      case CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE:
        contentFormModel = new HamiVideoConnectedTVImageFormModel(this);
        break;
      case CreativeType.HAMI_VIDEO_THIRD_PARTY:
        contentFormModel = new HamiVideoThirdPartyFormModel();
        break;
      case CreativeType.SANDBOX_OUTDOOR:
        contentFormModel = new SandboxOutdoorFormModel(advertiserId);
        break;
      case CreativeType.POYA_SHORT:
        contentFormModel = new PoyaFormModel(advertiserId, 0, 15);
        break;
      case CreativeType.POYA_LONG:
        contentFormModel = new PoyaFormModel(advertiserId, 16, 30);
        break;
      case CreativeType.POYA_EXTENDED:
        contentFormModel = new PoyaFormModel(advertiserId, 31, 60);
        break;
      default:
        throw new Error('unsupported type');
    }
    needInit && await this.initFormContentModel(contentFormModel);
    this.contentFormModelCache[type] = contentFormModel;
    return contentFormModel;
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel {
    const type = creativeBasicFormData.creativeType;
    switch (type) {
      case CreativeType.NATIVE:
        return new NativeSummaryModel(this.type, creativeBasicFormData, this.nativeToBannertemplates);
      case CreativeType.IMAGE:
        return new ImageSummaryModel(creativeBasicFormData);
      case CreativeType.VIDEO:
        return new VideoSummaryModel(creativeBasicFormData);
      case CreativeType.THIRD_PARTY:
        return new PPSSummaryModel(creativeBasicFormData);
      case CreativeType.HTML5:
        return new HTML5SummaryModel(creativeBasicFormData);
      case CreativeType.COMBO:
        return new ComboSummaryModel(creativeBasicFormData);
      case CreativeType.ONE_FOR_ALL_DISPLAY:
        return new OneForAllDisplaySummaryModel(creativeBasicFormData);
      case CreativeType.ONE_FOR_ALL_VIDEO:
        return new OneForAllVideoSummaryModel(creativeBasicFormData);
      case CreativeType.EDIMAX:
        return new EdiMaxSummaryModel(creativeBasicFormData);
      case CreativeType.PIC_SHORT:
      case CreativeType.PIC_LONG:
      case CreativeType.PIC_EXTENDED:
        return new PICSummaryModel(creativeBasicFormData);
      case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
        return new RetailRichMediaProductSummaryModel(creativeBasicFormData, _.get(this.initCreative.basic, 'typeProperties.productSetId'));
      case CreativeType.RETAIL_NATIVE_PRODUCT:
        return new RetailNativeProductSummaryModel(creativeBasicFormData, _.get(this.initCreative.basic, 'typeProperties.productSetId'));
      case CreativeType.PILOT_TV:
        return new PilotTVSummaryModel(creativeBasicFormData);
      case CreativeType.CTV:
        return new VideoSummaryModel(creativeBasicFormData);
      case CreativeType.CUSTOM_BOTTOM:
      case CreativeType.CUSTOM_RECTANGLE:
        return new CustomLayoutSummaryModel(creativeBasicFormData);
      case CreativeType.HAMI_VIDEO_NATIVE:
        const nativeToBannertemplates = {
          '300x250': this.nativeToBannertemplates['300x250'],
          '320x100': this.nativeToBannertemplates['320x100']
        };
        return new HamiVideoNativeSummaryModel(this.type, creativeBasicFormData, nativeToBannertemplates);
      case CreativeType.HAMI_VIDEO_IMAGE:
      case CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE:
        return new HamiVideoImageSummaryModel(creativeBasicFormData);
      case CreativeType.HAMI_VIDEO_VIDEO:
        return new HamiVideoVideoSummaryModel(creativeBasicFormData);
      case CreativeType.HAMI_VIDEO_COMBO:
        return new HamiVideoComboSummaryModel(creativeBasicFormData);
      case CreativeType.HAMI_VIDEO_THIRD_PARTY:
        return new HamiVideoThirdPartySummaryModel(creativeBasicFormData);
      case CreativeType.SANDBOX_OUTDOOR:
      case CreativeType.POYA_SHORT:
      case CreativeType.POYA_LONG:
      case CreativeType.POYA_EXTENDED:
        return new SandboxOutdoorSummaryModel(creativeBasicFormData);
      default:
        throw new Error('unsupported type');
    }
  }

  initAdvertiser = async (advertiserId: number) => {
    if (this.advertiser && this.advertiser.id === advertiserId) {
      return;
    }
    try {
      this.advertiser = await this.advertiserManager.getAdvertiser(advertiserId);
    } catch (e) {
      console.log(e);
    }
  }

  onChooseAdvertiser = async (advertiserId: number) => {
    this.updateState(true);
    await this.initAdvertiser(advertiserId);
    this.creative &&
      this.advertiser &&
      _.set(this.creative, 'basic.tenmaxCategory', this.advertiser.category);
    this.updateState(false);
  }

  cancel = () => {
    const campaignParam = this.draftId ? `draftIds=${this.draftId}` : `campaignIds=${this.campaignId}`;
    const redirectPath = _.isNil(this.orderNumber) || _.isNil(this.l1ObjectId) || (_.isNil(this.campaignId) && _.isNil(this.draftId)) ?
      '/creatives' :
      `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}?${campaignParam}&action=manage`;
    this.redirectData = {
      pathname: redirectPath
    };
    this.updateState(false);
  }

  setUpAdLogo = (creative: CreativeFormData, formContentModel?: FormContentModel) => {
    this.enableAdLogo = formContentModel ? formContentModel.isAdLogoSupported(creative.basic) : false;
    if (this.enableAdLogo && _.isNil(creative.basic.adLogo)) {
      creative.basic = {
        ...creative.basic,
        adLogo: this.defaultAdLogo
      };
    }
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectData = undefined;
    this.creative = undefined;
    this.creativeSetupStepModel = undefined;
    this.finished = false;
    this.uploadedFiles = {};
    this.contentFormModelCache = {};
    this.cache = {};
  }

  addCache (key: string, data: any) {
    this.cache[key] = data;
  }

  getCache (key: string) {
    return this.cache[key];
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class CreateCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  get type () {
    return 'create';
  }

  async init () {
    this.updateState(true);
    try {
      const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER)!;
      const creativeType = this.getInitCreativeType();
      await this.initNativeBannerTemplates();
      advertiserId && await this.initAdvertiser(advertiserId);
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const model = await this.getFormContentModelOfType(creativeType, advertiserId);
      this.creative = {
        basic: {
          advertiserId,
          creativeType: creativeType,
          tenmaxCategory: this.tenmaxCategories && this.tenmaxCategories.length > 0 ?
            this.tenmaxCategories[0].value.toString() : '',
          enableNativeBanner: isNative(creativeType) ? true : false
        },
        limitations: model ? model.getInitLimitations() : {
          include: [],
          exclude: [],
          nonPreferred: [],
          other: [],
          preferred: []
        }
      };

      this.advertiser && _.set(this.creative, 'basic.tenmaxCategory', this.advertiser.category);
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.setUpAdLogo(this.creative, model);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  async getFormContentModelOfType (type: CreativeType, advertiserId: number): Promise<FormContentModel> {
    if (this.contentFormModelCache[type]) {
      return this.contentFormModelCache[type];
    }
    let contentFormModel;
    if (type === CreativeType.IMAGE) {
      contentFormModel = new CreateImageFormModel(this);
    } else if (type === CreativeType.HAMI_VIDEO_IMAGE) {
      contentFormModel = new CreateHamiVideoImageFormModel(this);
    } else if (type === CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE) {
      contentFormModel = new CreateHamiVideoConnectedTVImageFormModel(this);
    } else {
      contentFormModel = await super.getFormContentModelOfType(type, advertiserId, false);
    }
    await this.initFormContentModel(contentFormModel);
    this.contentFormModelCache[type] = contentFormModel;
    return contentFormModel;
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel {
    const type = creativeBasicFormData.creativeType;
    if (type === CreativeType.IMAGE) {
      return new CreateImageMultipleSummaryModel(creativeBasicFormData);
    }
    if ([CreativeType.HAMI_VIDEO_IMAGE, CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE].includes(type)) {
      return new CreateHamiVideoImageMuitipleSummaryModel(creativeBasicFormData);
    }
    return super.getSummaryModel(creativeBasicFormData);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class EditCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  isCreativeForPmp: boolean = false;

  constructor (
    public creativeId: number,
    ...args: ConstructorParameters<typeof DefaultCreativeSetupFlowPageModel>
  ) {
    super(...args);
  }

  get type () {
    return 'edit';
  }

  get forPmp () {
    return this.isCreativeForPmp;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initNativeBannerTemplates();
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const creativeFromServer = await this.creativeManager.getCreative(this.creativeId);
      this.isCreativeForPmp = !(await this.creativeManager.checkCreativeSupportSchedule(this.creativeId));
      const creativeTenmaxCategory = creativeFromServer.basic.tenmaxCategory;
      if (!this.tenmaxCategories.find(tenmaxCategory => tenmaxCategory.value === creativeTenmaxCategory)) {
        this.tenmaxCategories.push({ label: creativeTenmaxCategory, value: creativeTenmaxCategory });
      }
      const {
        creativeId,
        advertiserId,
        creativeType
      } = creativeFromServer.basic;
      await this.initAdvertiser(advertiserId);
      const contentFormModel = await this.getFormContentModelOfType(
        creativeType,
        advertiserId
      );
      if (!contentFormModel) {
        this.redirectData = {
          pathname: `/creatives/${creativeId}/edit/${ROUTE_PATH.ERROR404}`
        };
        this.updateState(false);
        return;
      }
      this.creative = contentFormModel.getFormModelData(creativeFromServer);
      this.setUpAdLogo(this.creative, contentFormModel);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel {
    return new EditCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

abstract class CreateCreativeFromL2ObjectSetupFlowPageModel extends CreateCreativeSetupFlowPageModel {

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    defaultAdLogo: AdLogo,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    draftId?: string,
    adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId, adRequestResourceManager);
  }

  abstract get isChannelSupport ();

  abstract get supportedCreativeType ();

  abstract initCampaign ();

  async init () {
    if (!this.orderNumber) {
      return;
    }
    this.updateState(true);
    try {
      await this.initCampaign();
      await this.initNativeBannerTemplates();
      const order = await this.orderManager.getOrder(this.orderNumber);
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const advertiserId = order.advertiserId;
      await this.initAdvertiser(advertiserId);
      const creativeType = this.supportedCreativeType[0];
      const model = await this.getFormContentModelOfType(creativeType, advertiserId);
      this.creative = {
        basic: {
          advertiserId,
          creativeType,
          tenmaxCategory: this.tenmaxCategories && this.tenmaxCategories.length > 0 ?
            this.tenmaxCategories[0].value.toString() : '',
          enableNativeBanner: isNative(creativeType) ? true : false
        },
        limitations: model ? model.getInitLimitations() : {}
      };
      this.advertiser && _.set(this.creative, 'basic.tenmaxCategory', this.advertiser.category);
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.setUpAdLogo(this.creative, model);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {
      console.error(e);
    }
    this.updateState(false);
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel;
}

export class DefaultCreateCreativeFromL2ObjectSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  campaign?: RtbCampaign;

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    defaultAdLogo: AdLogo,
    private l1ObjectChannel: L1ObjectChannel,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    draftId?: string,
    adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    private campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    private draftManager: DraftManager = new RtbCampaignDraftManager(),
    private l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId, adRequestResourceManager);
  }

  async initCampaign () {
    if (this.draftId) {
      try {
        this.campaign = await this.draftManager.getDraft(this.draftId);
      } catch (e) {}
      return;
    }
    if (!this.campaignId) {
      return;
    }
    try {
      this.campaign = await this.campaignManager.getCampaign(this.campaignId);
    } catch (e) {
      console.log(e);
    }
  }

  get isChannelSupport () {
    return this.l1ObjectManager.isChannelAllowed(this.l1ObjectChannel, this.addonFeatureManager);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    if (!this.campaign || !this.campaign.basic.adType || !this.isChannelSupport) {
      return result;
    }

    AD_TYPE_MAP_CREATIVE_TYPE[this.campaign.basic.adType].forEach(
      creativeType => {
        this.creativeManager.isCreativeTypeSupport(
          creativeType,
          this.addonFeatureManager
        ) && result.push(creativeType);
      }
    );
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindRTBCampaignSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class CreateCreativeFromAdSetSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  async initCampaign () {
    // This is intentional
  }

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.FB);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.ONE_FOR_ALL_DISPLAY) &&
      result.push(CreativeType.ONE_FOR_ALL_DISPLAY);
    result.push(CreativeType.ONE_FOR_ALL_VIDEO);
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindAdSetSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class CreateCreativeInL2ObjectSetupFlowPageModel extends CreateCreativeSetupFlowPageModel {

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    defaultAdLogo: AdLogo,
    public campaign: RtbCampaign,
    private order: Order
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, defaultAdLogo);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    if (!this.campaign || !this.campaign.basic.adType) {
      return result;
    }

    AD_TYPE_MAP_CREATIVE_TYPE[this.campaign.basic.adType].forEach(creativeType => {
      this.creativeManager.isCreativeTypeSupport(
        creativeType,
        this.addonFeatureManager
      ) && result.push(creativeType);
    });
    return result.filter(type => this.validCreativeTypes.includes(type));
  }

  get forPmp () {
    return [AdType.PMP_PIC_LONG, AdType.PMP_PIC_SHORT].includes(this.campaign.basic.adType);
  }

  async init () {
    this.updateState(true);
    try {
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const campaignName = this.campaign.basic.name;
      const advertiserId = this.order.advertiserId;
      const creativeType = this.supportedCreativeType[0];
      await this.initAdvertiser(advertiserId);
      const model = await this.getFormContentModelOfType(creativeType, advertiserId);
      this.creative = {
        basic: {
          name: campaignName,
          advertiserId,
          creativeType,
          tenmaxCategory: this.tenmaxCategories && this.tenmaxCategories.length > 0
            ? this.tenmaxCategories[0].value.toString()
            : ''
        }
      };

      this.advertiser && _.set(this.creative, 'basic.tenmaxCategory', this.advertiser.category);
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {
      console.error(e);
    }
    this.updateState(false);
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel {
    const type = creativeBasicFormData.creativeType;
    if (type === CreativeType.IMAGE) {
      return new ImageSummaryModel(creativeBasicFormData);
    }
    if ([CreativeType.HAMI_VIDEO_IMAGE, CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE].includes(type)) {
      return new HamiVideoImageSummaryModel(creativeBasicFormData);
    }
    return super.getSummaryModel(creativeBasicFormData);
  }
}
